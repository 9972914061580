import coffeebreak from "./svg/coffeebreak.svg";
import logo from "./svg/logo.svg";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { Component } from "react";
import { post } from "axios";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { PrivacyPolicy } from "./privacy-policy.js";
import { API } from "./api.js";

const BASE_CODE = `post("https://api.dnsnow.app/subscribe", {
  domain: "example.com",
  webhook: "https://myexamplesaas.com/webhook"
})

// User updates the domain's DNS...

// POST https://myexamplesaas.com/webhook
`;
const CODE = [
`// {
//   "domain": "cname.example.com",
//   "changes": {
//     "TXT": [
//       "\\"some verification key\\""
//     ]
//   }
// }`,
`// {
//   "domain": "cname.example.com",
//   "changes": {
//     "MX": [
//       "supermail.yoursaas.com"
//     ]
//   }
// }`,
];

function NotFound() {
  return (
    <section className="flex items-center h-full p-16 bg-coolGray-50 text-coolGray-800">
      <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
        <div className="max-w-md text-center">
          <h2 className="mb-8 font-extrabold text-9xl text-coolGray-400">
            <span className="sr-only">Error</span>404
          </h2>
          <p className="text-2xl font-semibold md:text-3xl">
            Sorry, we couldn't find this page.
          </p>
          <p className="mt-4 mb-8 text-coolGray-600">
            But dont worry, you can find plenty of other things on our homepage.
          </p>
          <a
            href="#"
            className="px-8 py-3 font-semibold rounded bg-violet-600 text-blue-300"
          >
            <Link to="/">Back to homepage</Link>
          </a>
        </div>
      </div>
    </section>
  );
}

class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      codeIndex: 0,
    }
    this.changeCode = this.changeCode.bind(this);
  }

  componentDidMount() {
    setInterval(this.changeCode, 6000);
  }

  changeCode() {
    const codeIndex = (this.state.codeIndex + 1) % (CODE.length);
    console.log("hein", codeIndex, CODE[codeIndex]);
    this.setState({ codeIndex });
  }

  render() {
    const { subscribeEmail, emailSubmited } = this.props;
    return (
      <>
        <div className="shape lg:h-2/4 md:h-1/3 sm:h-1/3 h-2/4"></div>
        <div className="space-y-8 md:space-y-16 bg-coolGray-100 text-coolGray-800">
          <header className="p-4 bg-coolGray-100 text-coolGray-800">
            <div className="container flex justify-between h-48 mx-auto md:space-x-8">
              <img src={logo} className="h-3/5 m-auto sm:h-36 lg:m-0" />
            </div>
          </header>
          <section className="bg-coolGray-100 text-coolGray-800">
            <div className="container flex flex-col justify-center p-6 mx-auto lg:flex-row lg:justify-between lg:py-24 md:py-12 flex-col-reverse lg:flex-row">
              <div className="flex flex-col justify-center p-6 text-center rounded-sm lg:max-w-md xl:max-w-lg lg:text-left">
                <h2 className="text-5xl font-bold leading-none sm:text-6xl">
                  Make your products{" "}
                  <span className="text-blue-600">frictionless</span>
                </h2>
                <p className="mt-12 text-lg mb-6">
                  DNS can be slow and waiting for propagation frustrating.
                </p>
                <p className="text-lg mb-12">
                  Your SaaS requires a DNS change?
                  <br />
                  Be notified once the change is done. Cut down the waiting time.
                </p>
                <p className="text-lg mb-12">
      And more features to come.
                </p>
                <div className="flex flex-col space-y-4 sm:items-center sm:justify-center sm:flex-row sm:space-y-0 space-x-4 lg:justify-start">
                  <a
                    href="/login"
                    className="px-8 py-3 text-lg font-semibold rounded bg-yellow-600 text-coolGray-50"
                  >
                    Join the beta with GitHub
                  </a>
                </div>
              </div>
              <div className="flex items-center justify-center p-6 lg:mt-0 h-auto sm:h-80 lg:h-96 xl:h-112 2xl:h-128">
                <img width="840px" height="680px" src={coffeebreak} alt="women drinking a coffee and looking at her phone"/>
              </div>
            </div>
          </section>
          <section className="bg-coolGray-100 text-coolGray-800">
            <div className="container max-w-xl p-6 py-48 mx-auto lg:px-8 lg:max-w-7xl">
              <div>
                <h2 className="text-3xl font-bold tracking-tight text-center sm:text-5xl text-coolGray-900">
                  Here's How It Works
                </h2>
                <p className="max-w-3xl mx-auto mt-4 text-lg text-center text-coolGray-600">
                  &#8221;Talk is cheap. Show me the code&#8221; ― Linus Torvalds
                </p>
              </div>

              <div className="grid lg:gap-8 lg:grid-cols-2 lg:items-center md:py-24 py-20">
                <div className="window lg:w-10/12">
                  <div className="window-header">
                    <div className="action-buttons"></div>
                    <span className="language">JavaScript</span>
                  </div>
                  <div className="window-body">
                    <span className="code-input">
                      <SyntaxHighlighter language="javascript" style={docco}>
                        {BASE_CODE + CODE[this.state.codeIndex]}
                      </SyntaxHighlighter>
                    </span>
                    <pre className="code-output">
                      <code className="language-javascript"></code>
                    </pre>
                  </div>
                </div>

                <div className="md:py-24 py-20">
                  <h3 className="text-2xl font-bold tracking-tight sm:text-3xl text-coolGray-900">
                    Integrate our API in your SaaS onboarding
                  </h3>
                  <p className="mt-3 text-coolGray-600">
                    Need more information about the API?{" "}
                    <a href="https://docs.dnsnow.app" className="text-blue-300">
                      click here to check out our documentation
                    </a>
                    .
                  </p>
                  <div className="mt-12 space-y-12">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center w-12 h-12 rounded-md bg-yellow-600 text-coolGray-50">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            className="w-7 h-7"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M5 13l4 4L19 7"
                            ></path>
                          </svg>
                        </div>
                      </div>
                      <div className="ml-4">
                        <p className="mt-2 text-coolGray-600">
                          Tell us what domain you are interested in and where we
                          should send you updates.
                        </p>
                      </div>
                    </div>
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center w-12 h-12 rounded-md bg-yellow-600 text-coolGray-50">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            className="w-7 h-7"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M5 13l4 4L19 7"
                            ></path>
                          </svg>
                        </div>
                      </div>
                      <div className="ml-4">
                        <p className="mt-2 text-coolGray-600">
                          Once the domain's DNS changes you will be the first
                          person to know.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="w-full bg-coolGray-600 yellow-bg">
            <div className="container flex flex-col flex-wrap content-center justify-center p-4 mx-auto py-16 md:py-32 lg:py-48">
              <h1 className="text-5xl antialiased font-bold leading-none text-center md:text-6xl text-coolGray-100">
                Get Our Updates
              </h1>
              <p className="pt-2 pb-8 text-xl antialiased text-center text-coolGray-100">
                Find out about new features and other news before everyone else
              </p>
              <form onSubmit={subscribeEmail} className="flex flex-row">
                {emailSubmited ? (
                  <div className="flex space-x-8 text-blue-600">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                      />
                    </svg>
                    <p>Great! We will keep in touch.</p>
                  </div>
                ) : (
                  <>
                    <input
                      type="email"
                      name="email"
                      placeholder="example@email.com"
                      className="w-3/5 p-3 rounded-l-lg sm:w-2/3"
                    />
                    <button
                      type="submit"
                      style={{ color: "white" }}
                      className="w-2/5 p-3 font-semibold rounded-r-lg sm:w-1/3 bg-blue-300 text-white"
                    >
                      Subscribe
                    </button>
                  </>
                )}
              </form>
            </div>
          </div>
          <footer className="py-6 bg-coolGray-100 text-coolGray-900">
            <div className="container px-6 mx-auto space-y-6 divide-y divide-coolGray-400 md:space-y-12 divide-opacity-50">
              <div className="grid justify-center pt-6 lg:justify-between">
                <div className="flex flex-col self-center text-sm text-center md:block lg:col-start-1 md:space-x-6">
                  <h1 style={{ display: "inline" }}>DNS now</h1>
                  <span>
                    © 2021{" "}
                    <a
                      href="https://twitter.com/svensauleau"
                      target="_new"
                      className="text-blue-300"
                    >
                      Sven Sauleau
                    </a>
                  </span>
                  <span>
                    <a
                      href="mailto:sven@dnsnow.app"
                    >
                      sven@dnsnow.app
                    </a>
                  </span>
                  <Link className="text-blue-300" to="/privacy-policy">
                    Privacy policy
                  </Link>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </>
    );
  }
}

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = { emailSubmited: false };
    this.subscribeEmail = this.subscribeEmail.bind(this);
  }

  async subscribeEmail(e) {
    e.preventDefault();
    const email = e.target.elements.email.value;
    await post("/email", { email });
    this.setState({ emailSubmited: true });
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route path="/" exact>
            <Landing
              subscribeEmail={this.subscribeEmail}
              emailSubmited={this.state.emailSubmited}
            />
          </Route>
          <Route exact path="/api">
            <API />
          </Route>
          <Route exact path="/privacy-policy">
            <PrivacyPolicy />
          </Route>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </Router>
    );
  }
}
