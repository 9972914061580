import React, { Component } from "react";

export function PrivacyPolicy() {
  return (
    <div className="container space-y-8 md:space-y-16 bg-coolGray-100 text-coolGray-800 m-auto">
      <section className="my-8 bg-coolGray-100 text-coolGray-800">
        <div className="container items-center p-4 mx-auto space-y-6 md:p-8">
          <div className="flex space-x-4 justify-center m-auto my-32">
            <span className="text-blue-600">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                fill="currentColor"
                className="w-16 h-16 text-violet-600"
              >
                <polygon points="328.375 384 332.073 458.999 256.211 406.28 179.924 459.049 183.625 384 151.586 384 146.064 496 182.756 496 256.169 445.22 329.242 496 365.936 496 360.414 384 328.375 384"></polygon>
                <path d="M415.409,154.914l-2.194-48.054L372.7,80.933,346.768,40.414l-48.055-2.2L256,16.093,213.287,38.219l-48.055,2.2L139.3,80.933,98.785,106.86l-2.194,48.054L74.464,197.628l22.127,42.715,2.2,48.053L139.3,314.323l25.928,40.52,48.055,2.195L256,379.164l42.713-22.126,48.055-2.195,25.928-40.52L413.214,288.4l2.195-48.053,22.127-42.715Zm-31.646,76.949L382,270.377l-32.475,20.78-20.78,32.475-38.515,1.76L256,343.125l-34.234-17.733-38.515-1.76-20.78-32.475L130,270.377l-1.759-38.514L110.5,197.628,128.237,163.4,130,124.88,162.471,104.1l20.78-32.474,38.515-1.76L256,52.132l34.234,17.733,38.515,1.76,20.78,32.474L382,124.88l1.759,38.515L401.5,197.628Z"></path>
              </svg>
            </span>
            <h1 className="text-6xl">Privacy Policy</h1>
          </div>
          <div className="flex justify-center space-x-3">
            <article>
              <p>
                This privacy policy applies between you, the User of this
                Website and <span className="boiler-field">DNS now</span>, the
                owner and provider of this Website.{" "}
                <span className="boiler-field">DNS now</span> takes the privacy
                of your information very seriously. This privacy policy applies
                to our use of any and all Data collected by us or provided by
                you in relation to your use of the Website.
              </p>
              <p>
                <b>Please read this privacy policy carefully</b>.
              </p>
              <h2 className="text-3xl py-6 mt-8">
                Definitions and interpretation
              </h2>
              <ol
                className="list-disc list-inside"
                className="clauses firstList"
              >
                <li>
                  In this privacy policy, the following definitions are used:
                  <table className="definitions">
                    <tbody>
                      <tr>
                        <td>
                          <b>Data</b>
                        </td>
                        <td>
                          collectively all information that you submit to{" "}
                          <span className="boiler-field">DNS now</span> via the
                          Website. This definition incorporates, where
                          applicable, the definitions provided in the Data
                          Protection Laws;
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Cookies</b>
                        </td>
                        <td>
                          a small text file placed on your computer by this
                          Website when you visit certain parts of the Website
                          and/or when you use certain features of the Website.
                          Details of the cookies used by this Website are set
                          out in the clause below ( <b>Cookies</b>);
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Data Protection Laws</b>
                        </td>
                        <td>
                          any applicable law relating to the processing of
                          personal Data, including but not limited to the
                          Directive 96/46/EC (Data Protection Directive) or the
                          GDPR, and any national implementing laws, regulations
                          and secondary legislation, for as long as the GDPR is
                          effective in the UK;
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>GDPR</b>
                        </td>
                        <td>
                          the General Data Protection Regulation (EU) 2016/679;
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>
                            <span className="boiler-field">DNS now</span>,{" "}
                            <br />
                            we{" "}
                          </b>{" "}
                          or <b>us</b>
                        </td>
                        <td>
                          <span>
                            <span className="boiler-field">DNS now</span> of{" "}
                            <span className="boiler-field empty" />,
                            <span>
                              {" "}
                              <span className="boiler-field">London</span>,{" "}
                            </span>{" "}
                            <span>
                              {" "}
                              <span className="boiler-field">
                                England
                              </span>,{" "}
                            </span>{" "}
                            <span className="boiler-field empty" />;{" "}
                          </span>{" "}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>UK and EU Cookie Law</b>
                        </td>
                        <td>
                          the Privacy and Electronic Communications (EC
                          Directive) Regulations 2003 as amended by the Privacy
                          and Electronic Communications (EC Directive)
                          (Amendment) Regulations 2011;
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>User</b> or <b>you</b>
                        </td>
                        <td>
                          any third party that accesses the Website and is not
                          either (i) employed by{" "}
                          <span className="boiler-field">DNS now</span> and
                          acting in the course of their employment or (ii)
                          engaged as a consultant or otherwise providing
                          services to{" "}
                          <span className="boiler-field">DNS now</span> and
                          accessing the Website in connection with the provision
                          of such services; and
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Website</b>
                        </td>
                        <td>
                          the website that you are currently using,{" "}
                          <span className="boiler-field">dnsnow.app</span>, and
                          any sub-domains of this site unless expressly excluded
                          by their own terms and conditions.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
                <li>
                  In this privacy policy, unless the context requires a
                  different interpretation:
                  <ol className="list-disc list-inside">
                    <li>the singular includes the plural and vice versa;</li>
                    <li>
                      references to sub-clauses, clauses, schedules or
                      appendices are to sub-clauses, clauses, schedules or
                      appendices of this privacy policy;
                    </li>
                    <li>
                      a reference to a person includes firms, companies,
                      government entities, trusts and partnerships;
                    </li>
                    <li>
                      "including" is understood to mean "including without
                      limitation";
                    </li>
                    <li>
                      reference to any statutory provision includes any
                      modification or amendment of it;
                    </li>
                    <li>
                      the headings and sub-headings do not form part of this
                      privacy policy.
                    </li>
                  </ol>
                </li>
              </ol>
              <h2 className="text-3xl py-6 mt-8">
                Scope of this privacy policy
              </h2>
              <ol className="list-disc list-inside" className="clauses">
                <li>
                  This privacy policy applies only to the actions of{" "}
                  <span className="boiler-field">DNS now</span> and Users with
                  respect to this Website. It does not extend to any websites
                  that can be accessed from this Website including, but not
                  limited to, any links we may provide to social media websites.
                </li>
                <li>
                  For purposes of the applicable Data Protection Laws,{" "}
                  <span className="boiler-field">DNS now</span> is the "data
                  controller". This means that{" "}
                  <span className="boiler-field">DNS now</span> determines the
                  purposes for which, and the manner in which, your Data is
                  processed.
                </li>
              </ol>
              <div>
                <h2 className="text-3xl py-6 mt-8">Data collected</h2>
                <ol className="list-disc list-inside" className="clauses">
                  <li>
                    We may collect the following Data, which includes personal
                    Data, from you:
                    <ol className="list-disc list-inside">
                      <li>name;</li>
                      <li>contact Information such as email addresses;</li>
                    </ol>
                    <p>in each case, in accordance with this privacy policy.</p>
                  </li>
                </ol>
              </div>
              <h2 className="text-3xl py-6 mt-8">How we collect Data</h2>
              <ol className="list-disc list-inside" className="clauses">
                <li>
                  We collect Data in the following ways:
                  <ol className="list-disc list-inside">
                    <li>
                      data is given to us by you
                      <span>; and</span>
                    </li>
                    <li>data is collected automatically.</li>
                  </ol>
                </li>
              </ol>
              <h2 className="text-3xl py-6 mt-8">
                Data that is given to us by you
              </h2>
              <ol className="list-disc list-inside" className="clauses">
                <li>
                  <span className="boiler-field">DNS now</span> will collect
                  your Data in a number of ways, for example:
                  <ol className="list-disc list-inside">
                    <li>
                      when you contact us through the Website, by telephone,
                      post, e-mail or through any other means;
                    </li>
                    <li>
                      when you register with us and set up an account to receive
                      our products/services;
                    </li>
                  </ol>
                  <p>in each case, in accordance with this privacy policy.</p>
                </li>
              </ol>
              <h2 className="text-3xl py-6 mt-8">
                Data that is collected automatically
              </h2>
              <ol className="list-disc list-inside" className="clauses">
                <li>
                  To the extent that you access the Website, we will collect
                  your Data automatically, for example:
                  <ol className="list-disc list-inside">
                    <li>
                      we automatically collect some information about your visit
                      to the Website. This information helps us to make
                      improvements to Website content and navigation, and
                      includes your IP address, the date, times and frequency
                      with which you access the Website and the way you use and
                      interact with its content.
                    </li>
                    <li>
                      we will collect your Data automatically via cookies, in
                      line with the cookie settings on your browser. For more
                      information about cookies, and how we use them on the
                      Website, see the section below, headed "Cookies".
                    </li>
                  </ol>
                </li>
              </ol>
              <div>
                <h2 className="text-3xl py-6 mt-8">Our use of Data</h2>
                <ol className="list-disc list-inside" className="clauses">
                  <li>
                    Any or all of the above Data may be required by us from time
                    to time in order to provide you with the best possible
                    service and experience when using our Website. Specifically,
                    Data may be used by us for the following reasons:
                    <ol className="list-disc list-inside">
                      <li>internal record keeping;</li>
                      <li>improvement of our products / services;</li>
                      <li>
                        transmission by email of marketing materials that may be
                        of interest to you;
                      </li>
                    </ol>
                    <p>in each case, in accordance with this privacy policy.</p>
                  </li>
                  <li>
                    We may use your Data for the above purposes if we deem it
                    necessary to do so for our legitimate interests. If you are
                    not satisfied with this, you have the right to object in
                    certain circumstances (see the section headed "Your rights"
                    below).
                  </li>
                  <li>
                    For the delivery of direct marketing to you via e-mail,
                    we'll need your consent, whether via an opt-in or
                    soft-opt-in:
                    <ol className="list-disc list-inside">
                      <li>
                        soft opt-in consent is a specific type of consent which
                        applies when you have previously engaged with us (for
                        example, you contact us to ask us for more details about
                        a particular product/service, and we are marketing
                        similar products/services). Under "soft opt-in" consent,
                        we will take your consent as given unless you opt-out.
                      </li>
                      <li>
                        for other types of e-marketing, we are required to
                        obtain your explicit consent; that is, you need to take
                        positive and affirmative action when consenting by, for
                        example, checking a tick box that we'll provide.
                      </li>
                      <li>
                        if you are not satisfied about our approach to
                        marketing, you have the right to withdraw consent at any
                        time. To find out how to withdraw your consent, see the
                        section headed "Your rights" below.
                      </li>
                    </ol>
                  </li>
                  <li>
                    When you register with us and set up an account to receive
                    our services, the legal basis for this processing is the
                    performance of a contract between you and us and/or taking
                    steps, at your request, to enter into such a contract.
                  </li>
                </ol>
              </div>
              <h2 className="text-3xl py-6 mt-8">Keeping Data secure</h2>
              <ol className="list-disc list-inside" className="clauses">
                <li>
                  We will use technical and organisational measures to safeguard
                  your Data, for example:
                  <ol className="list-disc list-inside">
                    <li>
                      access to your account is controlled by a password and a
                      user name that is unique to you.
                    </li>
                    <li>we store your Data on secure servers.</li>
                  </ol>
                </li>
                <li>
                  Technical and organisational measures include measures to deal
                  with any suspected data breach. If you suspect any misuse or
                  loss or unauthorised access to your Data, please let us know
                  immediately by contacting us via this e-mail address:{" "}
                  <span className="boiler-field">sven@dnsnow.app</span>.
                </li>
                <li>
                  If you want detailed information from Get Safe Online on how
                  to protect your information and your computers and devices
                  against fraud, identity theft, viruses and many other online
                  problems, please visit www.getsafeonline.org. Get Safe Online
                  is supported by HM Government and leading businesses.
                </li>
              </ol>
              <h2 className="text-3xl py-6 mt-8">Data retention</h2>
              <ol className="list-disc list-inside" className="clauses">
                <li>
                  Unless a longer retention period is required or permitted by
                  law, we will only hold your Data on our systems for the period
                  necessary to fulfil the purposes outlined in this privacy
                  policy or until you request that the Data be deleted.
                </li>
                <li>
                  Even if we delete your Data, it may persist on backup or
                  archival media for legal, tax or regulatory purposes.
                </li>
              </ol>
              <h2 className="text-3xl py-6 mt-8">Your rights</h2>
              <ol className="list-disc list-inside" className="clauses">
                <li>
                  You have the following rights in relation to your Data:
                  <ol className="list-disc list-inside">
                    <li>
                      <b>Right to access</b> - the right to request (i) copies
                      of the information we hold about you at any time, or (ii)
                      that we modify, update or delete such information. If we
                      provide you with access to the information we hold about
                      you, we will not charge you for this, unless your request
                      is "manifestly unfounded or excessive." Where we are
                      legally permitted to do so, we may refuse your request. If
                      we refuse your request, we will tell you the reasons why.
                    </li>
                    <li>
                      <b>Right to correct</b> - the right to have your Data
                      rectified if it is inaccurate or incomplete.
                    </li>
                    <li>
                      <b>Right to erase</b> - the right to request that we
                      delete or remove your Data from our systems.
                    </li>
                    <li>
                      <b>Right to restrict our use of your Data</b> - the right
                      to "block" us from using your Data or limit the way in
                      which we can use it.
                    </li>
                    <li>
                      <b>Right to data portability</b> - the right to request
                      that we move, copy or transfer your Data.
                    </li>
                    <li>
                      <b>Right to object</b> - the right to object to our use of
                      your Data including where we use it for our legitimate
                      interests.
                    </li>
                  </ol>
                </li>
                <li>
                  To make enquiries, exercise any of your rights set out above,
                  or withdraw your consent to the processing of your Data (where
                  consent is our legal basis for processing your Data), please
                  contact us via this e-mail address:{" "}
                  <span className="boiler-field">sven@dnsnow.app</span>.
                </li>
                <li>
                  If you are not satisfied with the way a complaint you make in
                  relation to your Data is handled by us, you may be able to
                  refer your complaint to the relevant data protection
                  authority. For the UK, this is the Information Commissioner's
                  Office (ICO). The ICO's contact details can be found on their
                  website at https://ico.org.uk/.
                </li>
                <li>
                  It is important that the Data we hold about you is accurate
                  and current. Please keep us informed if your Data changes
                  during the period for which we hold it.
                </li>
              </ol>
              <div>
                <h2 className="text-3xl py-6 mt-8">
                  Transfers outside the European Economic Area
                </h2>
                <ol className="list-disc list-inside" className="clauses">
                  <li>
                    Data which we collect from you may be stored and processed
                    in and transferred to countries outside of the European
                    Economic Area (EEA). For example, this could occur if our
                    servers are located in a country outside the EEA or one of
                    our service providers is situated in a country outside the
                    EEA.
                    <span>
                      {" "}
                      We also share information with our group companies, some
                      of which are located outside the EEA.
                    </span>
                  </li>
                  <li>
                    We will only transfer Data outside the EEA where it is
                    compliant with data protection legislation and the means of
                    transfer provides adequate safeguards in relation to your
                    data, eg by way of data transfer agreement, incorporating
                    the current standard contractual clauses adopted by the
                    European Commission, or by signing up to the EU-US Privacy
                    Shield Framework, in the event that the organisation in
                    receipt of the Data is based in the United States of
                    America.
                  </li>
                  <li>
                    To ensure that your Data receives an adequate level of
                    protection, we have put in place appropriate safeguards and
                    procedures with the third parties we share your Data with.
                    This ensures your Data is treated by those third parties in
                    a way that is consistent with the Data Protection Laws.
                  </li>
                </ol>
              </div>
              <h2 className="text-3xl py-6 mt-8">Links to other websites</h2>
              <ol className="list-disc list-inside" className="clauses">
                <li>
                  This Website may, from time to time, provide links to other
                  websites. We have no control over such websites and are not
                  responsible for the content of these websites. This privacy
                  policy does not extend to your use of such websites. You are
                  advised to read the privacy policy or statement of other
                  websites prior to using them.
                </li>
              </ol>
              <h2 className="text-3xl py-6 mt-8">
                Changes of business ownership and control
              </h2>
              <ol className="list-disc list-inside" className="clauses">
                <li>
                  <span className="boiler-field">DNS now</span> may, from time
                  to time, expand or reduce our business and this may involve
                  the sale and/or the transfer of control of all or part of{" "}
                  <span className="boiler-field">DNS now</span>. Data provided
                  by Users will, where it is relevant to any part of our
                  business so transferred, be transferred along with that part
                  and the new owner or newly controlling party will, under the
                  terms of this privacy policy, be permitted to use the Data for
                  the purposes for which it was originally supplied to us.
                </li>
                <li>
                  We may also disclose Data to a prospective purchaser of our
                  business or any part of it.
                </li>
                <li>
                  In the above instances, we will take steps with the aim of
                  ensuring your privacy is protected.
                </li>
              </ol>
              <div>
                <h2 className="text-3xl py-6 mt-8">Cookies</h2>
                <ol className="list-disc list-inside" className="clauses">
                  <li>
                    This Website may place and access certain Cookies on your
                    computer.
                    <span>
                      {" "}
                      <span className="boiler-field">DNS now</span> uses Cookies
                      to improve your experience of using the Website and to
                      improve our range of services.{" "}
                    </span>{" "}
                    <span className="boiler-field">DNS now</span> has carefully
                    chosen these Cookies and has taken steps to ensure that your
                    privacy is protected and respected at all times.
                  </li>
                  <li>
                    All Cookies used by this Website are used in accordance with
                    current UK and EU Cookie Law.
                  </li>
                  <li>
                    Before the Website places Cookies on your computer, you will
                    be presented with a message bar requesting your consent to
                    set those Cookies. By giving your consent to the placing of
                    Cookies, you are enabling{" "}
                    <span className="boiler-field">DNS now</span> to provide a
                    better experience and service to you. You may, if you wish,
                    deny consent to the placing of Cookies; however certain
                    features of the Website may not function fully or as
                    intended.
                  </li>
                  <li>
                    This Website may place the following Cookies:
                    <table className="withBorders">
                      <tbody>
                        <tr>
                          <td align="center" valign="center" width="30%">
                            <b>Type of Cookie</b>
                          </td>
                          <td align="center" valign="center" width="70%">
                            <b>Purpose</b>
                          </td>
                        </tr>
                        <tr>
                          <td align="left" valign="top">
                            Strictly necessary cookies
                          </td>
                          <td align="left" valign="top">
                            These are cookies that are required for the
                            operation of our website. They include, for example,
                            cookies that enable you to log into secure areas of
                            our website, use a shopping cart or make use of
                            e-billing services.
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </li>
                  <li>
                    You can find a list of Cookies that we use in the Cookies
                    Schedule.
                  </li>
                  <li>
                    You can choose to enable or disable Cookies in your internet
                    browser. By default, most internet browsers accept Cookies
                    but this can be changed. For further details, please consult
                    the help menu in your internet browser.
                  </li>
                  <li>
                    You can choose to delete Cookies at any time; however you
                    may lose any information that enables you to access the
                    Website more quickly and efficiently including, but not
                    limited to, personalisation settings.
                  </li>
                  <li>
                    It is recommended that you ensure that your internet browser
                    is up-to-date and that you consult the help and guidance
                    provided by the developer of your internet browser if you
                    are unsure about adjusting your privacy settings.
                  </li>
                  <li>
                    For more information generally on cookies, including how to
                    disable them, please refer to aboutcookies.org. You will
                    also find details on how to delete cookies from your
                    computer.
                  </li>
                </ol>
              </div>
              <h2 className="text-3xl py-6 mt-8">General</h2>
              <ol className="list-disc list-inside" className="clauses">
                <li>
                  You may not transfer any of your rights under this privacy
                  policy to any other person. We may transfer our rights under
                  this privacy policy where we reasonably believe your rights
                  will not be affected.
                </li>
                <li>
                  If any court or competent authority finds that any provision
                  of this privacy policy (or part of any provision) is invalid,
                  illegal or unenforceable, that provision or part-provision
                  will, to the extent required, be deemed to be deleted, and the
                  validity and enforceability of the other provisions of this
                  privacy policy will not be affected.
                </li>
                <li>
                  Unless otherwise agreed, no delay, act or omission by a party
                  in exercising any right or remedy will be deemed a waiver of
                  that, or any other, right or remedy.
                </li>
                <li>
                  This Agreement will be governed by and interpreted according
                  to the law of <span>England and Wales</span>. All disputes
                  arising under the Agreement will be subject to the exclusive
                  jurisdiction of the <span>English and Welsh courts</span>.
                </li>
              </ol>
              <h2 className="text-3xl py-6 mt-8">
                Changes to this privacy policy
              </h2>
              <ol className="list-disc list-inside" className="clauses">
                <li>
                  <span className="boiler-field">DNS now</span> reserves the
                  right to change this privacy policy as we may deem necessary
                  from time to time or as may be required by law. Any changes
                  will be immediately posted on the Website and you are deemed
                  to have accepted the terms of the privacy policy on your first
                  use of the Website following the alterations. <br />
                  You may contact <span className="boiler-field">
                    DNS now
                  </span>{" "}
                  by email at{" "}
                  <span className="boiler-field">sven@dnsnow.app</span>.
                </li>
              </ol>
              <div>
                <h2 className="text-3xl py-6 mt-8">Cookies</h2>
                <p>
                  Below is a list of the cookies that we use. We have tried to
                  ensure this is complete and up to date, but if you think that
                  we have missed a cookie or there is any discrepancy, please
                  let us know.
                </p>
                <div>
                  <p>Strictly necessary</p>
                  <p>We use the following strictly necessary cookies:</p>
                  <div id="white" style={{ border: "1px solid #000" }}>
                    <div
                      id="startTable"
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        textAlign: "center",
                      }}
                    >
                      <div
                        id="th"
                        style={{
                          width: "50%",
                          padding: "5px",
                          borderRight: "1px solid transparent",
                        }}
                      >
                        <b>Description of Cookie</b>
                      </div>
                      <div
                        id="th"
                        style={{
                          width: "50%",
                          padding: "5px",
                          borderLeft: "1px solid #000",
                        }}
                      >
                        <b>Purpose</b>
                      </div>
                    </div>
                    <div>
                      <div
                        style={{
                          borderTop: "1px solid #000",
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <div
                          id="td"
                          style={{
                            width: "50%",
                            padding: "5px",
                            borderRight: "1px solid transparent",
                          }}
                        >
                          <span className="boiler-field">User session</span>
                        </div>
                        <div
                          id="td"
                          style={{
                            width: "50%",
                            padding: "5px",
                            borderLeft: "1px solid #000",
                          }}
                        >
                          <span className="boiler-field">
                            Authenticate the user
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </section>
    </div>
  );
}
