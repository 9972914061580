import React, { Component } from "react";
import svg from "./svg/registration.svg";
import { get } from "axios";

export class API extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiToken: "*".repeat(30),
    };
  }

  componentDidMount() {
    const apiToken = cookie("u");
    if (apiToken) {
      this.setState({ apiToken })
    }
  }

  render() {
    return (
      <>
        <div className="shape-bg h-3/5"></div>
        <div className="flex space-x-4 justify-center m-auto my-32">
          <span className="text-blue-600">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              fill="currentColor"
              className="w-16 h-16 text-blue-600"
            >
              <polygon points="328.375 384 332.073 458.999 256.211 406.28 179.924 459.049 183.625 384 151.586 384 146.064 496 182.756 496 256.169 445.22 329.242 496 365.936 496 360.414 384 328.375 384"></polygon>
              <path d="M415.409,154.914l-2.194-48.054L372.7,80.933,346.768,40.414l-48.055-2.2L256,16.093,213.287,38.219l-48.055,2.2L139.3,80.933,98.785,106.86l-2.194,48.054L74.464,197.628l22.127,42.715,2.2,48.053L139.3,314.323l25.928,40.52,48.055,2.195L256,379.164l42.713-22.126,48.055-2.195,25.928-40.52L413.214,288.4l2.195-48.053,22.127-42.715Zm-31.646,76.949L382,270.377l-32.475,20.78-20.78,32.475-38.515,1.76L256,343.125l-34.234-17.733-38.515-1.76-20.78-32.475L130,270.377l-1.759-38.514L110.5,197.628,128.237,163.4,130,124.88,162.471,104.1l20.78-32.474,38.515-1.76L256,52.132l34.234,17.733,38.515,1.76,20.78,32.474L382,124.88l1.759,38.515L401.5,197.628Z"></path>
            </svg>
          </span>
          <h1 className="text-6xl">Your account</h1>
        </div>

        <div className="container max-w-xl p-6 py-12 mx-auto space-y-24 lg:px-8 lg:max-w-7xl">
          <div
            className="flex lg:items-center rounded-lg shadow-lg bg-coolGray-500 bg-white p-12 auto-cols"
            style={{ background: "white" }}
          >
            <div className="w-full">
              <p className="mt-3 text-coolGray-600">
                Each calls to the DNSnow API must be authenticated using a
                security token.
              </p>
              <p className="mt-3 text-coolGray-600">
                The token is passed in the Authorization header like the
                following: <br />
                <code>Authorization: Bearer &lt;token&gt;</code>.
              </p>
              <p className="mt-3 text-coolGray-600">
                <a href="https://docs.dnsnow.app" className="text-blue-300">Click here to check out our documentation for more information
                about the API</a>.
              </p>
              <div className="mt-12 space-y-12">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center w-12 h-12 rounded-md text-coolGray-50">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="ml-4">
                    <h4 className="text-lg font-medium leading-6 text-coolGray-900">
                      Your API token
                    </h4>
                    <div className="text-gray-700 mt-4">
                      <label className="">API token:</label>
                      <input
                        className="mt-2 w-full bg-gray-400 px-4 py-3 rounded-md appearance-none border border-transparent focus:outline-none transition duration-200 ease-in-out focus:bg-white focus:border-gray-300 min-w-0"
                        value={this.state.apiToken}
                        type="text"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div aria-hidden="true" className="align-bottom">
              <img src={svg} alt="" className="align-bottom" />
            </div>
          </div>
        </div>
      </>
    );
  }
}

function cookie(a) {
    var d=[],
        e=document.cookie.split(";");
    a=RegExp("^\\s*"+a+"=\\s*(.*?)\\s*$");
    for(var b=0;b<e.length;b++){
        var f=e[b].match(a);
        f&&d.push(f[1])
    }
    return d
}
